import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"app":"","fixed":"","permanent":_vm.drawer.permanent,"mini-variant":_vm.drawer.mini},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VList,[(_vm.drawer.mini && _vm.drawer.compact)?_c(VListItem,[_c(VListItemAction,[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"primary"},on:{"click":_vm.togglePermanentDrawer}},on),[_vm._v(" "+_vm._s(_vm.drawer.permanent ? 'mdi-chevron-left' : 'mdi-chevron-right')+" ")])]}}],null,false,2557836563)},[_c('span',[_vm._v(_vm._s(_vm.drawer.permanent ? 'Løsne menyen' : 'Fest menyen'))])])],1)],1):_vm._e(),(_vm.drawer.mini)?_c(VListItem,[_c(VListItemAction,[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"primary"},on:{"click":_vm.toggleMiniDrawer}},on),[_vm._v(" mdi-aspect-ratio ")])]}}],null,false,2664651883)},[_c('span',[_vm._v("Full meny")])])],1)],1):_vm._e(),(!_vm.drawer.mini)?_c(VListItem,[_c(VListItemContent,[_c(VRow,{staticStyle:{"width":"100%"},attrs:{"no-gutters":""}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"primary"},on:{"click":_vm.toggleMiniDrawer}},on),[_vm._v(" mdi-aspect-ratio ")])]}}],null,false,2664651883)},[_c('span',[_vm._v("Bare ikoner")])]),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.drawer.compact)?_c(VIcon,_vm._g({attrs:{"color":"primary"},on:{"click":_vm.togglePermanentDrawer}},on),[_vm._v(" "+_vm._s(_vm.drawer.permanent ? 'mdi-chevron-left' : 'mdi-chevron-right')+" ")]):_vm._e()]}}],null,false,3845590962)},[_c('span',[_vm._v(_vm._s(_vm.drawer.permanent ? 'Løsne menyen' : 'Fest menyen'))])])],1)],1)],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.drawerOpen),callback:function ($$v) {_vm.drawerOpen=$$v},expression:"drawerOpen"}},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.authorizedRoutes),function(route,i){return _c(VListItem,{key:i,attrs:{"to":route.path}},[_c(VListItemIcon,[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"primary"}},on),[_vm._v(" "+_vm._s(route.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(route.title))])])],1),_c(VListItemTitle,[_vm._v(_vm._s(route.title))])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }