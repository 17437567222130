import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.yes.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,{staticClass:"subheading",domProps:{"innerHTML":_vm._s(_vm.htmlify(_vm.prompt))}}),_c(VCardActions,[_c(VSpacer),(_vm.buttons.yes)?_c(VBtn,{attrs:{"text":"","color":"primary"},nativeOn:{"click":function($event){return _vm.yes.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.buttons.yes)+" ")]):_vm._e(),(_vm.buttons.no)?_c(VBtn,{attrs:{"text":""},nativeOn:{"click":function($event){return _vm.no.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.buttons.no)+" ")]):_vm._e(),(_vm.buttons.cancel)?_c(VBtn,{attrs:{"text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.buttons.cancel)+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }