import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"app":"","color":"primary","dark":""}},[(!_vm.drawer.permanent)?_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.toggleDrawerOpen.apply(null, arguments)}}}):_vm._e(),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{attrs:{"icon":""}},[(!_vm.account.isAuthenticated)?_c(VIcon,_vm._g({},on),[_vm._v(" mdi-account-outline ")]):_vm._e(),(_vm.account.isAuthenticated)?_c(VIcon,_vm._g({},on),[_vm._v(" mdi-account ")]):_vm._e()],1)]}}])},[_c('CoreAccount')],1),_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,_vm._g({},on),[_vm._v(" mdi-cog ")])],1)]}}])},[_c('CoreSettings')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }