import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"min-width":"240px"}},[(_vm.account.isAuthenticated)?_c(VCardTitle,{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.account.userName)+" ")]):_vm._e(),(!_vm.account.isAuthenticated)?_c(VCardTitle,{staticClass:"title"},[_vm._v(" Ikke logget inn ")]):_vm._e(),_c(VCardText,[(_vm.account.isAuthenticated)?_c(VLayout,{attrs:{"column":"","justify-center":""}},[_c(VList,{attrs:{"dense":"","disabled":""}},[_c(VSubheader,[_vm._v("Roller:")]),_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.account.userRoles),function(role,i){return _c(VListItem,{key:i},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account-multiple")])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(role)}})],1)],1)}),1)],1)],1):_vm._e(),_c(VLayout,{attrs:{"column":"","justify-center":""}},[(_vm.account.isAuthenticated)?_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.account.signOut()}}},[_vm._v(" Logg ut ")]):_vm._e(),(!_vm.account.isAuthenticated)?_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.account.signIn()}}},[_vm._v(" Logg inn ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }