import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"min-width":"240px"}},[_c(VCardTitle,[_vm._v("Innstillinger")]),_c(VCardTitle,{staticClass:"subtitle-1"},[_vm._v(" Grensesnitt ")]),_c(VCardText,[_c(VLayout,{attrs:{"column":"","justify-center":""}},[_c(VFlex,[_c(VSwitch,{attrs:{"label":"Mørkt tema"},model:{value:(_vm.isDark),callback:function ($$v) {_vm.isDark=$$v},expression:"isDark"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }